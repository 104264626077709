
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import { videocamOutline, bookmarkOutline, navigateCircleOutline, bookOutline } from 'ionicons/icons';

export default {
  name: 'Tabs',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet },
  setup() {
    return {
      videocamOutline, 
      bookmarkOutline, 
      navigateCircleOutline,
      bookOutline
    }
  }
}
